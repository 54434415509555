<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-black text-white">
    <h1 class="text-6xl font-bold mb-6">Moon Kitty Pickle Parkour</h1>
    
    <!-- Placeholder for game image -->
    <img src="../assets/moonkitty.png" alt="Moon Kitty Pickle Parkour" class="w-1/2 max-w-md mb-6 rounded-lg shadow-lg" />
    
    <p class="text-2xl">Coming Soon</p>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>

<style scoped>
/* This scoped style is optional and can be customized */
body, html {
  margin: 0;
  padding: 0;
  background-color: black;
}
</style>
